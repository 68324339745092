.gff-cmp-cont-hide-show-more {
  &>.cmp-container {
    display: none;
  }

}



.gff-cmp-img-auto-width {
  .cmp-image {
    .cmp-image__image {
      width: auto;
    }
  }
}

.gff-cmp-cont-centrar {
  .cmp-container {
    .aem-Grid {
      display: flex;
      justify-content: center;
    }
  }
}

.gff-center-element-help > .cmp-container > .aem-Grid {
  display: flex;
  margin: 0 auto;
  max-width: 1120px;
}

.gff-center-access{
  .cmp-container {
    .aem-Grid {
      display: flex;
      justify-content: end;
    }
  }
}

.gff-cmp-cont-servicios {
  background-color: $Tonal-range-Info-info100;
  border-radius: 8px;
  width: 48% !important;
  margin: 0.5% 1%;
  ;


  .cmp-container {
    max-width: 530px;

    .aem-Grid {
      display: flex;
      padding: 16px;
      width: 95%;

      &>.image:first-child {
        display: flex;
        flex-direction: column;
        width: 48px;

        .cmp-image {
          margin: 0;
        }
      }

      &>.image:last-child {
        width: 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .cmp-image {
          margin: 0;
        }
      }

      &>.text {
        width: 85%;
        margin-left: 5%;
      }

      p {
        font-size: $xs;
        color: $Tonal-range-Neutral-neutral10;
        margin: 0;
        line-height: 20px;

        b {
          font-size: 18px;
          color: $Tonal-range-Primary-primary30;
        }
      }
    }


  }
}

.gff-cmp-cont-borde-gris {
  .cmp-container {
    border: $Tonal-range-Neutral-neutral80 1px solid;

  }
}

.gff-cmp-cont-body.gffaem-cmp-cont-1-col {
  &>.cmp-container {
    &>.aem-Grid {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .gff-cards-cont {
        margin: 0 auto;
      }
    }
  }
}

.gff-carousel-bloques__native-container.gff-cmp-cont-lista-cards {
  display: block;

  .cmp-container {
    .aem-Grid {
      .gff-cmp-cont-lista-cards {
        #carrusel-bloques.cmp-container {
          width: 100%;

          &>.aem-Grid {
            display: flex;
            justify-content: space-between;

            .gff-cmp-cont-borde-gris.gff-carousel-bloques__native-item {
              width: 254px;

              .cmp-container {
                border-radius: 16px;

                .aem-Grid {

                  .image {
                    display: flex;
                    justify-content: center;

                    .cmp-image {
                      margin-top: 14px;
                      margin-bottom: 16px;
                      width: 80px;

                      img {}
                    }
                  }

                  .text {
                    .cmp-text {
                      p {
                        b {
                          font-size: $sm;
                          color: $Tonal-range-Neutral-neutral30;
                        }

                        a {
                          color: $Tonal-range-Primary-primary30;
                          text-decoration: underline;
                        }
                      }
                    }
                  }

                  .title {
                    .cmp-title {
                      h6 {
                        font-size: $sm;
                        color: $Tonal-range-Neutral-neutral30;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.gff-breadcrumb-prod {
  .cmp-container {

    .aem-Grid {
      .gff-cmp-cont-body {
        .cmp-container {
          max-width: 1120px;

          .breadcrumb {
            .cmp-breadcrumb {
              .cmp-breadcrumb__list {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

/* Para pantallas de escritorio con un ancho mínimo de 1360px */
@media (min-width: 1360px) {
  .gff-cmp-cont-body {
    &>.cmp-container {
      max-width: $body-desktop;
      margin: 0 auto;
    }
  }

  .gffaem-cmp-cont-3-col {
    &>.cmp-container {
      max-width: $body-desktop;
      margin: 0 auto;

      &>.aem-Grid {
        &::before {
          content: none;
        }

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* Tres columnas de tamaño igual */
        gap: 57px;
        /* Espacio entre columnas y filas */

        .gff-cmp-cont-borde-gris {

          width: 352px;

          .cmp-container {
            padding: 16px;
            border-radius: 16px;
          }

        }

      }

    }
  }
}

.gffaem-cmp-cont-2-col {

  &.gff-cmp-cont-features {
    .cmp-container {
      .aem-Grid {
        .gff-cmp-cont-servicios {
          width: 100% !important;
        }
      }
    }

  }

  &>.cmp-container {
    max-width: $body-desktop;
    margin: 0 auto;

    &>.aem-Grid {
      &::before {
        content: none;
      }

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      /* Tres columnas de tamaño igual */
      gap: 16px;
    }



  }
}


/* Para tabletas con un ancho mínimo de 768px y máximo de 1359px */
@media (min-width: 768px) and (max-width: 1359px) {

  .gff-center-element-help > .cmp-container > .aem-Grid {
    flex-direction: column;
    width: auto;
    padding: 0 32px;
  }

  .gff-cmp-cont-body {
    &>.cmp-container {
      max-width: 704px;
      margin: 0 auto;
    }
  }

  .gffaem-cmp-cont-3-col {

    #carrusel-bloques.cmp-container {
      width: 159% !important;
      height: 398px;

      .aem-Grid {
        height: 375px;
      }
    }

    .cmp-container {
      .aem-Grid {

        &::before {
          content: none;
        }

        flex-wrap: wrap;
        justify-content: center;
        gap: 35px;
        display: flex;

      }

    }
  }

  .gffaem-cmp-cont-2-col {
    &.gff-cmp-cont-features {
      .cmp-container {
        .aem-Grid {
          justify-content: center;
          gap: 16px;
          flex-direction: column;
          display: flex;

          .gff-cmp-cont-servicios {
            width: 90% !important;
            margin: 0 auto;
          }
        }
      }
    }

    .cmp-container {
      .aem-Grid {

        &::before {
          content: none;
        }

        flex-wrap: wrap;
        justify-content: space-around;
        gap: 16px;
        display: flex;

      }

    }
  }

  .gff-cmp-cont-body {
    max-width: 768px;
    margin: 0 !important;
    padding: 0 12px;
  }

  .gff-cmp-cont-servicios {
    background-color: $Tonal-range-Info-info100;
    border-radius: 8px;
    width: 100% !important;


    .cmp-container {

      max-width: 704px;

      .aem-Grid {
        display: flex;
        padding: 16px;
        width: 100%;

        &>.image:first-child {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 24px;

          .cmp-image {
            margin: 0;
          }
        }

        &>.image:last-child {
          width: 7px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .cmp-image {
            margin: 0;
          }
        }

        p {
          font-size: $xs;
          color: $Tonal-range-Neutral-neutral10;
          margin: 0;
          line-height: 20px;

          b {
            font-size: 18px;
            color: $Tonal-range-Primary-primary30;
          }
        }

        .text {
          width: 88%;
          max-width: 666px;
          margin-left: 10px;
        }
      }


    }
  }

  .gff-breadcrumb-prod {
    .cmp-container {

      .aem-Grid {
        .gff-cmp-cont-body {
          .cmp-container {
            max-width: 1360px;

            .breadcrumb {
              .cmp-breadcrumb {
                .cmp-breadcrumb__list {
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .gff-carousel-bloques__native-container.gff-cmp-cont-lista-cards {
    display: block;

    .cmp-container {
      .aem-Grid {
        .gff-cmp-cont-lista-cards {
          #carrusel-bloques.cmp-container {
            width: 160% !important;

            &>.aem-Grid {
              display: flex;
              justify-content: space-between;

              .gff-cmp-cont-borde-gris.gff-carousel-bloques__native-item {
                width: 254px;

                .cmp-container {
                  border-radius: 16px;

                  .aem-Grid {

                    .image {
                      display: flex;
                      justify-content: center;

                      .cmp-image {
                        margin-top: 14px;
                        margin-bottom: 16px;
                        width: 80px;

                        img {}
                      }
                    }

                    .text {
                      .cmp-text {
                        p {
                          b {
                            font-size: $sm;
                            color: $Tonal-range-Neutral-neutral30;
                          }

                          a {
                            color: $Tonal-range-Primary-primary30;
                            text-decoration: underline;
                          }
                        }
                      }
                    }

                    .title {
                      .cmp-title {
                        h6 {
                          font-size: $sm;
                          color: $Tonal-range-Neutral-neutral30;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Para dispositivos móviles con un ancho máximo de 767px */
@media (max-width: 767px) {

  .gff-cmp-cont-body {
    &>.cmp-container {
      max-width: 328px;
      margin: 0 auto;
    }
  }

  .gffaem-cmp-cont-3-col {

    #carrusel-bloques.cmp-container {
      width: 135% !important;
      height: 398px;

      .aem-Grid {
        height: 375px;
        flex-wrap: nowrap;
        justify-content: space-evenly;

        .gff-carousel-bloques__native-item {
          padding-right: 0;

          &:first-child {
            margin-left: 3px;
          }
        }
      }
    }

    .cmp-container {
      .aem-Grid {

        &::before {
          content: none;
        }

        flex-wrap: wrap;
        justify-content: center;
        gap: 35px;
        display: flex;

      }

    }

    .gffaem-cmp-cont-relacionado {
      .cmp-container {
        .gffaem-cmp-cuerpo-card {
          .cmp-container {
            .gff-cmp-txt-body-card {
              // height: 105px;
            }
          }
        }
      }
    }
  }

  .gffaem-cmp-cont-2-col {
    &.gff-cmp-cont-features {
      .cmp-container {
        .aem-Grid {
          justify-content: center;
          gap: 16px;
          flex-direction: column;
          display: flex;

          .gff-cmp-cont-servicios {
            width: 90% !important;
            margin: 0 auto;
          }
        }
      }
    }

    .cmp-container {
      .aem-Grid {

        &::before {
          content: none;
        }

        flex-wrap: wrap;
        justify-content: space-around;
        gap: 16px;
        display: flex;

      }

    }
  }

  .gff-cmp-cont-body {
    // max-width: 328px;
    margin: 0 !important;

  }

  .gff-cmp-cont-servicios {
    background-color: $Tonal-range-Info-info100;
    border-radius: 8px;
    width: 100% !important;
    margin: 8px 0;


    .cmp-container {
      max-width: 328px;
      height: 83px;

      .aem-Grid {
        display: flex;
        padding: 0;
        width: 100%;
        height: 83px;

        &>.image:first-child {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 48px;
          margin-left: 16px;

          .cmp-image {
            margin: 0;
          }
        }

        &>.image:last-child {
          width: 7px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .cmp-image {
            margin: 0;
          }
        }

        p {
          font-size: $xs;
          color: $Tonal-range-Neutral-neutral10;
          margin: 0;
          line-height: 20px;

          b {
            font-size: 18px;
            color: $Tonal-range-Primary-primary30;
          }
        }

        .text {
          max-width: 210px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }


    }
  }


  .gff-breadcrumb-prod {
    .cmp-container {

      .aem-Grid {
        .gff-cmp-cont-body {
          .cmp-container {
            max-width: 1360px;

            .breadcrumb {
              .cmp-breadcrumb {
                .cmp-breadcrumb__list {
                  padding-left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .gff-center-element-help > .cmp-container > .aem-Grid {
    flex-direction: column;
    width: auto;
    padding: 0 16px;
  }
}